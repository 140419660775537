import React, { Component } from 'react';
import {Grid, TextField, Button, Divider} from '@material-ui/core';
import Web3 from 'web3';
import shajs from 'sha.js';

import './App.css';

const contractDetails = require('./data/Notary.json');

class App extends Component {

  state = {
    message: null,
    public_cert: null,
    signature: null,
    notary: null,
    events: []
  }

  componentDidMount = async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
            // Request account access if needed
            await window.ethereum.enable();
            this.setState({
              notary: new window.web3.eth.Contract(contractDetails.abi, contractDetails.networks["3"]["address"])
            })
            // Acccounts now exposed
        } catch (error) {
            // User denied account access...
        }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
        // Acccounts always exposed
        this.setState({
          notary: new window.web3.eth.Contract(contractDetails.abi, contractDetails.networks["3"]["address"])
        })
    }
    // Non-dapp browsers...
    else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  }

  handleSign = (msg) => {
    //hash msg
    const hash = shajs('sha256').update(msg).digest('hex');
    // Sign
    window.hwcrypto.getCertificate({lang: "en"}).then( response => {
        var cert = response;
        this.setState({
          public_cert: window.hexToPem(response.hex)
        });
        window.hwcrypto.sign(cert, {type: "sha256", hex: hash}, {lang: "en"}).then( (response) => {
            this.setState({
              signature: response.hex
            });
        }, function(err) {
            alert("sign failed!");
        });
    }, function(err) {
      alert("Could not find certificate. Are you sure your card reader and ID card are working?")
      console.log(err);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container alignItems="center" justify="center" style={{width: "100%"}} spacing={32}>
        <Grid item xs={3} />
          <Grid item xs={6}>
            <TextField label="Enter Your Message" style={{width: "100%"}} onChange={(e)=>this.setState({message: e.target.value})}/>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3} />
          <Grid item xs={6}>
            <div>
              <p><strong>Certificate:</strong></p>
              <p>{this.state.public_cert}</p>
            </div>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3} />
          <Grid item xs={6}>
            <div>
              <p><strong>Signature:</strong></p>
              <p>{this.state.signature}</p>
            </div>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={4} />
          <Grid item xs={4}>

            <Button variant="contained" color="primary" style={{margin: 20}} disabled={!this.state.message} onClick={() => this.handleSign(this.state.message)}>
              Sign message
            </Button>

            <Button variant="contained" color="primary" style={{margin: 20}} disabled={(!this.state.public_cert) || (!this.state.signature)} onClick={() => 
              this.state.notary.methods.notarizeMessage(this.state.message, this.state.signature, this.state.public_cert).send({
                from: window.web3.eth.accounts.givenProvider.selectedAddress
              }).then((receipt) => {
                  console.log(receipt)
            })}>
              Submit
            </Button>

            <Button variant="contained" color="primary" style={{margin: 20}} onClick={() => 
              this.state.notary.getPastEvents("NotarizedMessage", {
                filter: {address: window.web3.eth.accounts.givenProvider.selectedAddress},
                fromBlock: 0,
                toBlock: 'latest'
            })
            .then((events) => {
                this.setState({events: events}) // same results as the optional callback above
            })}>
              Get Events
            </Button>

          </Grid>
          <Grid item xs={4} />

          <Grid item xs={1} />
          <Grid item xs={10}>
            {this.state.events.length > 0 ? <div>
                <p><strong>Tips: </strong></p>
                <ul>
                  <li> You can check the details contained in a certificate by copy/pasting <a href="https://www.sslshopper.com/certificate-decoder.html">the certificate here</a></li>
                  <li> Anyone can validate your message by checking the signature <a href="https://gist.github.com/dvas0004/3f2dbf2a3ce16bdc865766b990da0e6f">(have a look here for more details)</a></li>
                </ul>
                <Divider />
              </div> : null
            }
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={1} />
          <Grid item xs={10}>
            {this.state.events.map(e => <div>
                <p style={{backgroundColor: "#33BBFF"}}><strong>Message: </strong> <br/> {e.returnValues._message}</p>
                <p><strong>Blockchain Transaction:</strong> <br /> <a href={`https://ropsten.etherscan.io/tx/${e.transactionHash}`}>View on etherscan</a></p>
                <p style={{wordBreak: "break-all"}}><strong>Certificate: </strong> <br/> {e.returnValues.public_cert}</p>
                <p style={{wordBreak: "break-all"}}><strong>Signature: </strong> <br/> {e.returnValues._signature}</p>
                <Divider />
              </div>
            )}
          </Grid>
          <Grid item xs={1} />

        </Grid>
      </div>
    );
  }
}

export default App;

// https://medium.com/metamask/https-medium-com-metamask-breaking-change-injecting-web3-7722797916a8